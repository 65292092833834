<template>
    <el-main>
        <div class="btn">
            <el-button type="primary" class="el-icon-plus" size="small" @click="handleAdd">添加</el-button>
        </div>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
            <el-table-column prop="title" label="标题" min-width="200" align="center"></el-table-column>
            <el-table-column prop="like_count" label="点赞数" min-width="100" align="center"></el-table-column>
            <el-table-column label="操作" align="center" min-width="200">
                <template v-slot="{ row }">
                    <el-button type="text" size="small" @click="handleEdit(row)">编辑</el-button>
                    <el-button type="text" size="small" @click="handleDelete(row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--  -->
        <el-dialog :title="form.id ? '编辑' : '添加'" :visible.sync="showDialog" :before-close="handleClose" width="800px">
            <el-form class="goods-search" label-width="90px">
                <el-form-item label="标题：">
                    <el-input v-model="form.title" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="点赞：">
                    <el-input v-model="form.like_count" placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showDialog = false">取 消</el-button>
                <el-button type="primary" @click="handleConfirm">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
export default {
    data() {
        return {
            list: [],
            showDialog: false,
            form: {
                card_id: '',
                title: '',
                like_count: '',
            },
        };
    },
    created() {
        this.form.card_id = this.$route.query.id
        this.impressionList();
    },
    methods: {
        impressionList() {
            this.$axios.post(this.$api.cardManage.impressionList, {
                card_id: this.form.card_id,
            }).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleDelete(id) {
            this.$confirm(`确定要删除此项吗`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$axios
                        .post(this.$api.cardManage.impressionDelete, {
                            id: id
                        })
                        .then((res) => {
                            if (res.code === 0) {
                                this.$message.success('删除成功')
                                this.impressionList()
                            }
                        })
                })
                .catch(() => { })
        },
        handleConfirm() {
            let url = this.form.id ? this.$api.cardManage.impressionEdit : this.$api.cardManage.impressionAdd
            this.$axios
                .post(url, this.form)
                .then((res) => {
                    if (res.code === 0) {
                        this.$message.success('操作成功')
                        this.showDialog = false;
                        this.impressionList();
                    } else {
                        this.$message.error(res.msg);
                    }
                })
        },
        handleEdit(row) {
            this.form.title = row.title;
            this.form.like_count = row.like_count;
            this.form.id = row.id;
            this.showDialog = true;
        },
        handleAdd() {
            this.form = {
                card_id: this.form.card_id,
                title: '',
                like_count: '',
            };
            this.showDialog = true;
        },
        handleClose() {
            this.showDialog = false;
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
}
</style>